import WebGLHelper from 'ol/webgl/Helper';
import olLayerTile from "ol/layer/WebGLTile";
import olLayerNotWebGLTile from "ol/layer/Tile";

import {LayerHelper} from 'ngeo/map/LayerHelper';
import angular from 'angular';
import olFormatWMTSCapabilities from 'ol/format/WMTSCapabilities';
import {isEmpty} from 'ol/obj';
import olSourceWMTS, {optionsFromCapabilities} from 'ol/source/WMTS';

import {SyncLayertreeMap} from 'ngeo/layertree/SyncLayertreeMap';
import {getNodeMinResolution, getNodeMaxResolution} from 'gmf/theme/Themes';

import {Controller as LayertreeCtrl} from 'ngeo/layertree/component';
import {ServerType} from 'ngeo/datasource/OGC';

import {DatasourceManager} from 'ngeo/datasource/Manager';
import GmfDatasourceOGC from 'gmf/datasource/OGC';
import olSourceImageWMS from 'ol/source/ImageWMS';
import olSourceTileWMS from 'ol/source/TileWMS';

import {Controller as EditCtrl} from 'ngeo/editing/editFeatureComponent';
import {getLayer as syncLayertreeMapGetLayer} from 'gmf/layertree/SyncLayertreeMap';
import DateFormatter from 'ngeo/misc/php-date-formatter';
import {deleteCondition} from 'ngeo/utils';
import ngeoInteractionRotate from 'ngeo/interaction/Rotate';
import ngeoInteractionTranslate from 'ngeo/interaction/Translate';
import ngeoMiscToolActivate from 'ngeo/misc/ToolActivate';
import {getUid as olUtilGetUid} from 'ol/util';
import olCollection from 'ol/Collection';
import {listen} from 'ol/events';
import olInteractionModify from 'ol/interaction/Modify';
import olLayerImage from 'ol/layer/Image';
import olStyleFill from 'ol/style/Fill';
import olStyleStyle from 'ol/style/Style';
import olStyleText from 'ol/style/Text';
import VectorSource from 'ol/source/Vector';
import olLayerVector from 'ol/layer/Vector';
import {buildStyle} from 'ngeo/options';

import LegendMapFishPrintV3 from 'ngeo/print/LegendMapFishPrintV3';
import olLayerGroup from 'ol/layer/Group';

import {PrintService} from 'ngeo/print/Service';
import * as olSize from 'ol/size';
import olTilegridWMTS from 'ol/tilegrid/WMTS';

// ====================================
// New code, should be in ngeo-utils
// ====================================

let isWebGLSupportedCheck;
/**
 * Rely on deep OL usage of WebGL to know if WebGL is
 * supported or not.
 * @returns true if WebGl is supported, false otherwise.
 */
export const isWebGLSupported = () => {
  if (isWebGLSupportedCheck !== undefined) {
    return isWebGLSupportedCheck;
  }
  try {
    const helper = new WebGLHelper();
    const gl = helper.getGL();
    if ((gl instanceof WebGLRenderingContext)) {
      const vertexShaderSource = gl.createShader(gl.VERTEX_SHADER);
      helper.compileShader(
        vertexShaderSource,
        gl.VERTEX_SHADER,
      );
      isWebGLSupportedCheck = true;
    } else {
      isWebGLSupportedCheck = false;
    }
  } catch (e) {
    isWebGLSupportedCheck = false;
    console.error(e);
  }
  if (!isWebGLSupportedCheck) {
    console.error(
      'WebGL is not supported on this browser. The portal could be slower and some functions could not work as expected.'
    );
  }
  return isWebGLSupportedCheck;
};

/**
 * Return a WebGLTile if WebGL is supported. Otherwise, fallback on not WebGL Tile Layer.
 * @param {import('ol/layer/WebGLTile').Options} [options] the layer options.
 * @returns {(import('ol/layer/WebGLTile').default | import('ol/layer/Tile').default)}
 * @static
 */
export const createLayerTileOrWebGLTile = (options) => {
  if (isWebGLSupported()) {
    return new olLayerTile(options);
  }
  return new olLayerNotWebGLTile(options);
};

// ===========================================
// Class override are grouped and highlighted
// by sections below.
// Search for "OVERRIDE" to understand changes
// ===========================================

// ====================================
// Override of LayerHelper
// ====================================

/**
 * Create and return a promise that provides a WMTS layer with source on
 * success, no layer else.
 * The WMTS layer source will be configured by the capabilities that are
 * loaded from the given capabilitiesUrl.
 * The style object described in the capabilities for this layer will be added
 * as key 'capabilitiesStyles' as param of the new layer.
 *
 * @param {string} capabilitiesURL The getCapabilities url.
 * @param {string} layerName The name of the layer.
 * @param {string} [opt_matrixSet] Optional WMTS matrix set.
 * @param {Object<string, ?string>} [opt_dimensions] WMTS dimensions.
 * @param {Object} [opt_customOptions] Some initial options.
 * @param {number} [opt_minResolution] WMTS minimum resolution.
 * @param {number} [opt_maxResolution] WMTS maximum resolution.
 * @param {number} [opt_opacity] The opacity.
 * @returns {angular.IPromise<import('ol/layer/WebGLTile').default<import('ol/source/Tile').default>>} A Promise with a layer (with source) on
 *    success, no layer else.
 */
LayerHelper.prototype.createWMTSLayerFromCapabilitites = function (
  capabilitiesURL,
  layerName,
  opt_matrixSet,
  opt_dimensions,
  opt_customOptions,
  opt_minResolution,
  opt_maxResolution,
  opt_opacity
) {
  opt_maxResolution = this.fixResolution_(opt_maxResolution);
  const parser = new olFormatWMTSCapabilities();
  // OVERRIDE start
  const layerOptions = {
    preload: this.tilesPreloadingLimit_,
    minResolution: opt_minResolution,
    maxResolution: opt_maxResolution,
    className: 'canvas3d',
  };
  const layer = createLayerTileOrWebGLTile(layerOptions);
  // OVERRIDE end
  const $q = this.$q_;

  return this.$http_.get(capabilitiesURL, {cache: true}).then((response) => {
    let result;
    if (response.data) {
      result = parser.read(response.data);
    }
    if (result) {
      const options = Object.assign(
        {},
        opt_customOptions,
        optionsFromCapabilities(result, {
          matrixSet: opt_matrixSet,
          crossOrigin: 'anonymous',
          layer: layerName,
        })
      );
      const source = new olSourceWMTS(/** @type {import('ol/source/WMTS').Options} */ (options));
      if (opt_dimensions && !isEmpty(opt_dimensions)) {
        source.updateDimensions(opt_dimensions);
      }
      layer.setSource(source);

      // Add styles from capabilities as param of the layer
      const layers = result.Contents.Layer;
      const l = layers.find((elt) => elt.Identifier == layerName);
      if (!l) {
        return $q.reject(`Layer ${layerName} not available in WMTS capabilities from ${capabilitiesURL}`);
      }
      layer.set('capabilitiesStyles', l.Style);
      if (opt_opacity !== undefined) {
        layer.setOpacity(opt_opacity);
      }

      return $q.resolve(layer);
    }
    return $q.reject(`Failed to get WMTS capabilities from ${capabilitiesURL}`);
  });
};

/**
 * Create and return a WMTS layer using a formatted capabilities response
 * and a capability layer.
 *
 * @param {Object<string, any>} capabilities The complete capabilities object of the service
 * @param {Object<string, any>} layerCap The layer capability object
 * @param {Object<string, string>} [opt_dimensions] WMTS dimensions.
 * @returns {import('ol/layer/WebGLTile').default<import('ol/source/Tile').default>} WMTS layer
 */
LayerHelper.prototype.createWMTSLayerFromCapabilititesObj = function (
  capabilities,
  layerCap,
  opt_dimensions
) {
  const options = optionsFromCapabilities(capabilities, {
    crossOrigin: 'anonymous',
    layer: layerCap.Identifier,
    className: 'canvas3d',
  });

  console.assert(options);
  const source = new olSourceWMTS(/** @type {import('ol/source/WMTS').Options} */ (options));

  if (opt_dimensions && !isEmpty(opt_dimensions)) {
    source.updateDimensions(opt_dimensions);
  }

  // OVERRIDE start
  const layerOptions = {
    preload: Infinity,
    source: source,
    className: 'canvas3d',
  };
  const result = createLayerTileOrWebGLTile(layerOptions);
  // OVERRIDE end
  result.set('capabilitiesStyles', layerCap.Style);
  return result;
};

// ====================================
// Override of SyncLayertreeMap
// ====================================

/**
 * Create and return a Tile layer.
 *
 * @param {import('gmf/themes').GmfLayerWMTS} gmfLayerWMTS A leaf node.
 * @returns {import('ol/layer/WebGLTile').default<import('ol/source/Tile').default>} a Tile WMTS layer. (Source and capabilities can come
 *     later).
 */
SyncLayertreeMap.prototype.createWMTSLayer_ = function (gmfLayerWMTS) {
  // OVERRIDE start
  const newLayer = createLayerTileOrWebGLTile();
  // OVERRIDE end
  if (!gmfLayerWMTS.url) {
    throw new Error('Missing gmfLayerWMTS.url');
  }
  if (!gmfLayerWMTS.layer) {
    throw new Error('Missing gmfLayerWMTS.layer');
  }
  const minResolution = getNodeMinResolution(gmfLayerWMTS);
  const maxResolution = getNodeMaxResolution(gmfLayerWMTS);

  this.layerHelper_
    .createWMTSLayerFromCapabilitites(
      gmfLayerWMTS.url,
      gmfLayerWMTS.layer,
      gmfLayerWMTS.matrixSet,
      gmfLayerWMTS.dimensions,
      undefined,
      minResolution,
      maxResolution,
      gmfLayerWMTS.metadata.opacity
    )
    .then((layer) => {
      this.layerHelper_.copyProperties(layer, newLayer, ['visible', 'opacity']);
    });
  return newLayer;
};

// ===========================================
// Override of layertree/component controller.
// ===========================================

/**
 * Get the legends object (<LayerName: url> for each layer) for the given treeCtrl.
 *
 * @param {import('ngeo/layertree/Controller').LayertreeController} treeCtrl ngeo layertree controller,
 *    from the current node.
 * @returns {?Object<string, string>} A <layerName: url> object that provides a
 *     layer for each layer.
 */
LayertreeCtrl.prototype.getLegendsObject = function (treeCtrl) {
  /** @type {Object<string, string>} */
  const legendsObject = {};
  if (/** @type {import('gmf/themes').GmfGroup} */ (treeCtrl.node).children !== undefined) {
    return null;
  }

  const gmfLayer = /** @type {import('gmf/themes').GmfLayer} */ (treeCtrl.node);
  const gmfLayerDefaultName = gmfLayer.name;
  if (gmfLayer.metadata.legendImage) {
    legendsObject[gmfLayerDefaultName] = gmfLayer.metadata.legendImage;
    return legendsObject;
  }

  const layer = treeCtrl.layer;
  if (gmfLayer.type === 'WMTS') {
    // OVERRIDE start
    if (!(layer instanceof olLayerTile || layer instanceof olLayerNotWebGLTile)) {
      // OVERRIDE end
      throw new Error('Wrong layer');
    }
    const wmtsLegendURL = this.layerHelper_.getWMTSLegendURL(layer);
    if (wmtsLegendURL !== undefined) {
      legendsObject[gmfLayerDefaultName] = wmtsLegendURL;
    }
    return wmtsLegendURL ? legendsObject : null;
  } else {
    const gmfLayerWMS = /** @type {import('gmf/themes').GmfLayerWMS} */ (/** @type {any} */ (gmfLayer));
    const layersNames = gmfLayerWMS.layers;
    const gmfOgcServer = this.gmfTreeManager_.getOgcServer(treeCtrl);
    const scale = this.getScale_();
    // QGIS can handle multiple layers natively. Use Multiple URLs for other map
    // servers
    let layerNamesList;
    if (gmfOgcServer.type === ServerType.QGISSERVER) {
      layerNamesList = [layersNames];
    } else {
      layerNamesList = layersNames.split(',');
    }
    layerNamesList.forEach((layerName) => {
      const wmtsLegendURL = this.layerHelper_.getWMSLegendURL(gmfOgcServer.url, layerName, scale);
      if (!wmtsLegendURL) {
        throw new Error('Missing wmtsLegendURL');
      }
      legendsObject[layerName] = wmtsLegendURL;
    });
    return legendsObject;
  }
};

// ===============================
// Override of DatasourceManager.
// ===============================

/**
 * Update layer filter parameter according to data sources filter rules
 * and dimensions filters.
 *
 * @param {import('ol/layer/Base').default} layer The layer to update.
 * @private
 * @hidden
 */
DatasourceManager.prototype.updateLayerFilter_ = function (layer) {
  // OVERRIDE start
  if (!(layer instanceof olLayerImage || layer instanceof olLayerTile || layer instanceof olLayerNotWebGLTile)) {
    // OVERRIDE end
    return;
  }
  const source = layer.getSource();
  if (!(source instanceof olSourceImageWMS || source instanceof olSourceTileWMS)) {
    return;
  }

  const params = source.getParams();
  const layersParam = params.LAYERS;
  const layersList = layersParam.split(',');
  if (!layersList.length) {
    throw new Error('Missing layersList');
  }

  const filterParam = 'FILTER';
  const filterParamValues = [];
  let hasFilter = false;
  for (const wmsLayerName of layersList) {
    let filterParamValue = '()';

    const dataSources = this.dataSources_.getArray();
    for (const dataSource of dataSources) {
      const dsLayer = this.getDataSourceLayer_(dataSource);
      if (dsLayer == undefined) {
        continue;
      }
      if (!(dataSource instanceof GmfDatasourceOGC)) {
        throw new Error('Wrong dataSource type');
      }
      const gmfLayerWMS = /** @type {import('gmf/themes').GmfLayerWMS} */ (
        /** @type {any} */ (dataSource.gmfLayer)
      );
      if (
        olUtilGetUid(dsLayer) == olUtilGetUid(layer) &&
        layer.get('querySourceIds').includes(String(dataSource.id)) &&
        gmfLayerWMS.layers.split(',').includes(wmsLayerName)
      ) {
        const id = olUtilGetUid(dataSource.gmfLayer);
        const item = this.treeCtrlCache_[id];
        if (!item) {
          throw new Error('Missing item');
        }
        const treeCtrl = item.treeCtrl;
        const projCode = treeCtrl.map.getView().getProjection().getCode();

        if (!(dataSource instanceof GmfDatasourceOGC)) {
          throw new Error('Wrong datasource');
        }
        const filterString = dataSource.visible
          ? this.ngeoRuleHelper_.createFilterString({
              dataSource: dataSource,
              projCode: projCode,
              incDimensions: true,
            })
          : null;
        if (filterString) {
          filterParamValue = `(${filterString})`;
          hasFilter = true;
        }
      }
    }

    filterParamValues.push(filterParamValue);
  }
  source.updateParams({
    [filterParam]: hasFilter ? filterParamValues.join('') : null,
  });
}

// ===========================================
// Override of GmfEditfeatureController
// ===========================================

EditCtrl.prototype.$onInit = function () {
  if (!this.map) {
    throw new Error('Missing map');
  }

  /**
   * @type {import('ol/layer/Vector').default<import('ol/source/Vector').default<import('ol/geom/Geometry').default>>}
   */
  this.highlightVectorLayer_ = new olLayerVector({
    className: 'canvas2d',
    source: new VectorSource({
      wrapX: false,
      features: new olCollection(),
    }),
    style: buildStyle(this.options_.highlightStyle),
  });
  this.highlightVectorLayer_.setMap(this.map);
  this.hightlightedFeatures_ = this.highlightVectorLayer_.getSource().getFeaturesCollection();

  const lang = this.gettextCatalog_.getCurrentLanguage();

  // @ts-ignore: $.datetimepicker is available, as it is imported
  $.datetimepicker.setLocale(lang);
  // @ts-ignore: $.datetimepicker is available, as it is imported
  $.datetimepicker.setDateFormatter(new DateFormatter());

  // (1) Set default values and other properties
  this.dirty = this.dirty === true;
  if (!this.editableTreeCtrl) {
    throw new Error('Missing editableTreeCtrl');
  }
  this.editableNode_ = /** @type {import('gmf/themes').GmfLayer} */ (this.editableTreeCtrl.node);
  if (!this.vectorLayer) {
    throw new Error('Missing vectorLayer');
  }
  const source = this.vectorLayer.getSource();
  if (!(source instanceof VectorSource)) {
    throw new Error('Wrong source');
  }
  this.features = source.getFeaturesCollection();
  this.tolerance = this.tolerance || 10;

  // (1.1) Set editable WMS layer
  const layer = syncLayertreeMapGetLayer(this.editableTreeCtrl);
  // OVERRIDE start
  if (layer instanceof olLayerImage || layer instanceof olLayerTile || layer instanceof olLayerNotWebGLTile) {
    // OVERRIDE end
    this.editableWMSLayer_ = layer;
  }

  // (1.2) Create, set and initialize interactions
  this.modify_ = new olInteractionModify({
    deleteCondition: deleteCondition,
    features: this.features,
    style: this.ngeoFeatureHelper_.getVertexStyle(false),
  });
  this.interactions_.push(this.modify_);

  this.rotate_ = new ngeoInteractionRotate({
    features: this.features,
    style: new olStyleStyle({
      text: new olStyleText({
        text: '\uf01e',
        font: '900 18px "Font Awesome 5 Free"',
        fill: new olStyleFill({
          color: '#7a7a7a',
        }),
      }),
    }),
  });
  this.interactions_.push(this.rotate_);

  this.translate_ = new ngeoInteractionTranslate({
    features: this.features,
    style: new olStyleStyle({
      text: new olStyleText({
        text: '\uf0b2',
        font: '900 18px "Font Awesome 5 Free"',
        fill: new olStyleFill({
          color: '#7a7a7a',
        }),
      }),
    }),
  });
  this.interactions_.push(this.translate_);

  this.initializeInteractions_();

  this.modifyToolActivate = new ngeoMiscToolActivate(this.modify_, 'active');
  this.rotateToolActivate = new ngeoMiscToolActivate(this.rotate_, 'active');
  this.translateToolActivate = new ngeoMiscToolActivate(this.translate_, 'active');

  // (1.3) Add menus to map
  this.map.addOverlay(this.menu_);
  this.map.addOverlay(this.menuVertex_);

  // (2) Watchers and event listeners
  this.scope_.$watch(
    () => this.createActive,
    (newVal, oldVal) => {
      if (newVal) {
        this.gmfSnapping_.ensureSnapInteractionsOnTop();
      }
    }
  );

  this.scope_.$on('$destroy', this.handleDestroy_.bind(this));

  const uid = olUtilGetUid(this);
  this.ngeoEventHelper_.addListenerKey(uid, listen(this.features, 'add', this.handleFeatureAdd_, this));

  this.scope_.$watch(() => this.mapSelectActive, this.handleMapSelectActiveChange_.bind(this));

  this.scope_.$watch(
    () => this.state,
    (newValue, oldValue) => {
      const state = EditingState;
      if (newValue === state.STOP_EDITING_PENDING) {
        if (this.feature && this.dirty) {
          this.confirmCancel().then(() => {
            this.timeout_(() => {
              this.state = state.STOP_EDITING_EXECUTE;
              this.scope_.$apply();
            }, 500);
          });
        } else {
          this.state = state.STOP_EDITING_EXECUTE;
        }
      } else if (newValue === state.DEACTIVATE_PENDING) {
        if (this.feature && this.dirty) {
          this.confirmCancel().then(() => {
            this.timeout_(() => {
              this.state = state.DEACTIVATE_EXECUTE;
              this.scope_.$apply();
            }, 500);
          });
        } else {
          this.state = state.DEACTIVATE_EXECUTE;
        }
      }
    }
  );

  this.scope_.$watch(
    () => this.unsavedModificationsModalShown,
    (newValue, oldValue) => {
      // Reset stop request when closing the confirmation modal
      if (oldValue && !newValue) {
        this.state = EditingState.IDLE;
      }
    }
  );

  // (3) Get attributes
  this.gmfXSDAttributes_.getAttributes(this.editableNode_.id).then(this.setAttributes_.bind(this));

  // (4) Toggle
  this.toggle_(true);
};

// ===========================================
// Override of LegendMapFishPrintV3
// ===========================================

/**
 * Extract recursively a legend from a node and regardings activated layers.
 *
 * @param {import('gmf/themes').GmfGroup|import('gmf/themes').GmfLayer} node the current themes node.
 * @param {import("ol/layer/Base").default} layer or layer group to extract the legend from.
 * @param {number} scale The scale to get the legend.
 * @param {number} dpi The DPI.
 * @param {number[]} bbox The bbox.
 * @returns {import('ngeo/print/mapfish-print-v3').MapFishPrintLegendClass} Legend classes.
 * @private
 */
LegendMapFishPrintV3.prototype.collectLegendClassesInTree_ = function(node, layer, scale, dpi, bbox) {
  const gettextCatalog = this.gettextCatalog_;
  /** @type {import('ngeo/print/mapfish-print-v3').MapFishPrintLegendClass} */
  const legendGroupItem = {};

  // Case of parent node: create a new legend class with the node title and iter on children.
  if (node.hasOwnProperty('children')) {
    const nodeGroup = /** @type {import('gmf/themes').GmfGroup} */ (node);
    if (this.gmfLegendOptions_.showGroupsTitle) {
      legendGroupItem.name = gettextCatalog.getString(nodeGroup.name);
    }
    /** @type {import('ngeo/print/mapfish-print-v3').MapFishPrintLegendClass[]} */
    const groupClasses = [];
    legendGroupItem.classes = groupClasses;
    nodeGroup.children.forEach((nodeChild) => {
      const associatedLayer = this.ngeoLayerHelper_.getLayerByNodeName(nodeChild.name, [layer]) || layer;
      const child = this.collectLegendClassesInTree_(nodeChild, associatedLayer, scale, dpi, bbox);
      this.addClassItemToArray_(groupClasses, child);
    });
    return this.tryToSimplifyLegendGroup_(legendGroupItem);
  }

  if (layer instanceof olLayerGroup) {
    return;
  }

  // Case of leaf node: Create a legend class item matching the layer.
  const nodeLeaf = /** @type {import('gmf/themes').GmfLayer} */ (node);
  const layerLeaf = /** @type {import('ol/layer/Layer').default<import('ol/source/Source').default>} */ (
    layer
  );
  // Layer is not visible then return nothing.
  if (!layerLeaf.getVisible()) {
    return null;
  }
  // Layer is a tile, get the legend for this tile layer.
  // OVERRIDE start
  if (layerLeaf instanceof olLayerTile || layerLeaf instanceof olLayerNotWebGLTile) {
    // OVERRIDE end
    return this.getLegendItemFromTileLayer_(nodeLeaf, layerLeaf, dpi);
  }
  // Layer is a wms layer.
  const NodeWms = /** @type {import('gmf/themes').GmfLayerWMS} */ (/** @type {any} */ (nodeLeaf));
  const layerWms = /** @type {import('ol/layer/Layer').default<import('ol/source/ImageWMS').default>} */ (
    layerLeaf
  );
  // Get the legend if it has activated (visible) layer names.
  const layerNames = layerWms.getSource().getParams().LAYERS;
  if (!layerNames.includes(NodeWms.layers)) {
    return null;
  }
  return this.getLegendItemFromlayerWms_(NodeWms, layerWms, scale, dpi, bbox);
}

// ====================================
// Override of LayerHelper
// ====================================

/**
 * @param {import('ngeo/print/mapfish-print-v3').MapFishPrintLayer[]} arr Array.
 * @param {import('ol/layer/Base').default} layer Layer.
 * @param {number} resolution Resolution.
 * @param {number} destinationPrintDpi The destination print DPI.
 */
PrintService.prototype.encodeLayer = function (arr, layer, resolution, destinationPrintDpi) {
  if (layer instanceof olLayerImage) {
    this.encodeImageLayer_(arr, layer);
    // OVERRIDE start
  } else if (layer instanceof olLayerTile || layer instanceof olLayerNotWebGLTile) {
    // OVERRIDE end
    this.encodeTileLayer_(arr, layer);
  } else if (layer instanceof olLayerVector) {
    this.encodeVectorLayer(arr, layer, resolution, destinationPrintDpi);
  }
};

/**
 * @param {import('ngeo/print/mapfish-print-v3').MapFishPrintLayer[]} arr Array.
 * @param {import('ol/layer/WebGLTile').default<import('ol/source/Tile').default>} layer Layer.
 */
PrintService.prototype.encodeTileLayer_ = function (arr, layer) {
  // OVERRIDE start
  if (!(layer instanceof olLayerTile || layer instanceof olLayerNotWebGLTile)) {
    // OVERRIDE end
    throw new Error('layer not instance of olLayerTile');
  }
  const source = layer.getSource();
  if (source instanceof olSourceWMTS) {
    this.encodeTileWmtsLayer_(arr, layer);
  } else if (source instanceof olSourceTileWMS) {
    this.encodeTileWmsLayer_(arr, layer);
  }
};

/**
 * @param {import('ngeo/print/mapfish-print-v3').MapFishPrintLayer[]} arr Array.
 * @param {import('ol/layer/WebGLTile').default<import('ol/source/Tile').default>} layer Layer.
 */
PrintService.prototype.encodeTileWmtsLayer_ = function (arr, layer) {
  // OVERRIDE start
  if (!(layer instanceof olLayerTile || layer instanceof olLayerNotWebGLTile)) {
    // OVERRIDE end
    throw new Error('layer not instance of olLayerTile');
  }
  const source = layer.getSource();
  if (!(source instanceof olSourceWMTS)) {
    throw new Error('source not instance of olSourceWMTS');
  }

  const projection = source.getProjection();
  if (!projection) {
    throw new Error('Missing projection');
  }
  const metersPerUnit = projection.getMetersPerUnit();
  if (!metersPerUnit) {
    throw new Error('Missing metersPerUnit');
  }
  const tileGrid = source.getTileGrid();
  if (!(tileGrid instanceof olTilegridWMTS)) {
    throw new Error('tileGrid not instance of olTilegridWMTS');
  }
  const matrixIds = tileGrid.getMatrixIds();

  /** @type {import('ngeo/print/mapfish-print-v3').MapFishPrintWmtsMatrix[]} */
  const matrices = [];

  for (let i = 0, ii = matrixIds.length; i < ii; ++i) {
    const tileRange = tileGrid.getFullTileRange(i);
    matrices.push(
      /** @type {import('ngeo/print/mapfish-print-v3').MapFishPrintWmtsMatrix} */ ({
        identifier: matrixIds[i],
        scaleDenominator: (tileGrid.getResolution(i) * metersPerUnit) / 0.28e-3,
        tileSize: olSize.toSize(tileGrid.getTileSize(i)),
        topLeftCorner: tileGrid.getOrigin(i),
        matrixSize: [tileRange.maxX - tileRange.minX, tileRange.maxY - tileRange.minY],
      })
    );
  }

  const dimensions = source.getDimensions();
  const dimensionKeys = Object.keys(dimensions);

  const object = /** @type {import('ngeo/print/mapfish-print-v3').MapFishPrintWmtsLayer} */ ({
    baseURL: this.getWmtsUrl_(source),
    dimensions: dimensionKeys,
    dimensionParams: dimensions,
    imageFormat: source.getFormat(),
    layer: source.getLayer(),
    matrices: matrices,
    matrixSet: source.getMatrixSet(),
    opacity: this.getOpacityOrInherited_(layer),
    requestEncoding: source.getRequestEncoding(),
    style: source.getStyle(),
    type: 'WMTS',
    version: source.getVersion(),
  });

  arr.push(object);
};

/**
 * @param {import('ngeo/print/mapfish-print-v3').MapFishPrintLayer[]} arr Array.
 * @param {import('ol/layer/WebGLTile').default<import('ol/source/Tile').default>} layer Layer.
 */
PrintService.prototype.encodeTileWmsLayer_ = function (arr, layer) {
  // OVERRIDE start
  if (!(layer instanceof olLayerTile || layer instanceof olLayerNotWebGLTile)) {
    // OVERRIDE end
    throw new Error('layer not instance of olLayerTile');
  }
  const source = layer.getSource();
  if (!(source instanceof olSourceTileWMS)) {
    throw new Error('source not instance of olSourceTileWMS');
  }

  const urls = source.getUrls();
  if (!urls) {
    throw new Error('Missing urls');
  }
  this.encodeWmsLayer_(arr, layer, urls[0], source.getParams());
};
